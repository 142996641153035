import React, { useEffect, useState } from 'react'
import {
  invoiceStatus,
  dateFormat,
  trimCardCode,
  trimCardName,
  trimSellerName
} from '../share/format'
import { agent } from '../share/api'
import { loginUser } from '../share/loginUser'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Pagination from 'react-bootstrap/Pagination'
import PageItem from 'react-bootstrap/PageItem'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import logo from '../logo.svg'
import '../App.css'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import enums from '../share/enums'

const initalSearchState = {
  "nowPage": 1,
  "pageSize": 50,
  "sortField": "DateTime",
  "sortAction": "DESC",
  "openSearchMore": true,
  "byField": 0,
  "byKeyword": "",
  "dateTimeS": '2021-08-01',
  "dateTimeE": dateFormat(new Date()),
}

const SearchComponent = ({ setSearchOptions }) => {
  const [prepareQuery, setPrepareQuery] = useState(initalSearchState)
 
  const handleInputChange = event => {
    const { name, value } = event.target
    setPrepareQuery({ ...prepareQuery, [name]: value })
  }

  return (
    <Form onSubmit={event => event.preventDefault()}>
      <Form.Row>
        <Col xs={6} md={6} lg={4}>
          <Form.Label>開始日期</Form.Label>
          <Form.Control
            type="date"
            name="dateTimeS"
            value={prepareQuery.dateTimeS}
            onChange={handleInputChange}
          />
        </Col>
        <Col xs={6} md={6} lg={4}>
          <Form.Label>結束日期</Form.Label>
          <Form.Control
            type="date"
            name="dateTimeE"
            value={prepareQuery.dateTimeE}
            onChange={handleInputChange}
          />
        </Col>
      </Form.Row>
      <hr />
      <Form.Row>
        <Col sm>
          <Button
            onClick={() => {
              setSearchOptions(prepareQuery)
            }}>
            查詢
          </Button>
          <Button
            onClick={() => {
              setSearchOptions(initalSearchState)
              setPrepareQuery(initalSearchState)
            }}>
            列出全部
          </Button>       
        </Col>
      </Form.Row>
    </Form>
  )
}

const TableComponent = ({ data, searchOptions, setSearchOptions }) => {

  const sortIcon = {
    DESC: '▼',
    ASC: '▲',
    NONE: '◇'
  }
  const tableHeadClick = sortField => {
    const sortAction = 'DESC'
    const action = searchOptions.sortAction === 'DESC' ? 'ASC' : 'DESC'
    setSearchOptions(
      Object.assign({}, searchOptions, { sortField }, { sortAction: action })
    )
  }
  const withSortIcon = sortField =>
    searchOptions.sortField == sortField
      ? sortIcon[searchOptions.sortAction]
      : sortIcon.NONE

  const serialNumberStart = (searchOptions.nowPage - 1) * searchOptions.pageSize
  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th style={{ width: "5%" }}>序號</th>
          <th onClick={ev => tableHeadClick('DateTime')}>時間日期<small>{withSortIcon('DateTime')}</small></th>
          <th>訊息</th>
          <th onClick={ev => tableHeadClick('IsSuccess')}>同步成功<small>{withSortIcon('IsSuccess')}</small></th>
          <th onClick={ev => tableHeadClick('Sender')}>觸發者<small>{withSortIcon('Sender')}</small></th>
        </tr>
      </thead>
      <tbody>
      {data.map((it, index) => {     
        return <tr key={it.EInvoiceAPIDownloadHistoryID}>
            <td>{serialNumberStart + index + 1}</td>
            <td>{dateFormat(it.DateTime)}</td>
            <td>{it.Message}</td>
            <td>{it.IsSuccess ? '是': '否'}</td> 
            <td>{enums.EInvoiceAPIDownloadHistorySender[it.Sender]}</td>        
          </tr>      
      })}
      </tbody>
    </Table>
  )
}

const Pager = ({ totalPage, setSearchOptions, searchOptions }) => {
  const handlePageClick = page => {
    setSearchOptions({ ...searchOptions, nowPage: page })
  }
  const currentPage = parseInt(searchOptions.nowPage)
  const firstPage = currentPage > 10 ? currentPage - (currentPage % 10) : 1
  const totalPageItem = totalPage < 11 ? totalPage : 10
  return (
    <Pagination>
      <Pagination.First onClick={() => handlePageClick(1)} />
      <Pagination.Prev
        onClick={() => handlePageClick(currentPage === 1 ? 1 : currentPage - 1)}
      />
      {[...Array(totalPageItem)].map((_, index) => {
        const page = firstPage + index
        if (page <= totalPage) {
          return (
            <Pagination.Item
              key={page}
              onClick={() => handlePageClick(page)}
              active={currentPage === page}>
              {page}
            </Pagination.Item>
          )
        }
      })}
      <Pagination.Next
        onClick={() =>
          handlePageClick(
            totalPage == currentPage ? currentPage : currentPage + 1
          )
        }
      />
      <Pagination.Last onClick={() => handlePageClick(totalPage)} />
    </Pagination>
  )
}

const fetchInvoice = searchOptions =>
  Promise.all(
    [agent.GetHistoryListIndex].map(url =>
      fetch(url, {
        body: JSON.stringify(searchOptions),
        headers: {
          'user-agent': 'Mozilla/4.0 MDN Example',
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + loginUser().Token
        },
        method: 'POST'
      })
    )
  ).then(response => Promise.all(response.map(it => it.json())))

const HistoryList = () => {
  const [historylist, setHistorylist] = useState([])
  const [searchOptions, setSearchOptions] = useState(initalSearchState)

  useEffect(() => {
    setHistorylist(
      Object.assign({}, historylist, { isSuccess: false, message: '查詢中...' })
    )
    fetchInvoice(searchOptions)
      .then(([query]) => {        
        const totalPage = Math.ceil(query.Data.TotalItemCount / searchOptions.pageSize)
        return setHistorylist(
          Object.assign({}, { collection: query.Data.Rows, isSuccess: query.IsSuccess, TotalItemCount: query.Data.TotalItemCount, totalPage })
        )
      })
      .catch(({ message }) => {
        setHistorylist(Object.assign({}, { isSuccess: false, message }))
      })
  }, [searchOptions])

  return (
    <Col>
      <SearchComponent setSearchOptions={setSearchOptions} />
      {historylist.isSuccess ? (
        <div>
          <TableComponent
            data={historylist.collection}
            searchOptions={searchOptions}
            setSearchOptions={setSearchOptions}
          />
          共{historylist.TotalItemCount}筆資料 第{searchOptions.nowPage}頁/共
          {historylist.totalPage}頁
          <Pager
            totalPage={historylist.totalPage}
            setSearchOptions={setSearchOptions}
            searchOptions={searchOptions}
          />
        </div>
      ) : (
        <div>{historylist.message || '載入中...'}</div>
      )}
    </Col>
  )
}

export default HistoryList
