import {
  invoiceStatus,
  dateFormat,
  trimCardCode,
  trimCardName,
  trimSellerName,
  wrapDate
} from '../share/format'

const readAllLine = text => {
  const lines = text.split('\n').filter(it => it)
  return {
    lines,
    linesSplit: lines.map(it => it.split('|')).filter(it => it)
  }
}

const versionBool = {
  '2018-11&12': lines =>
    lines[0].includes(
      '表頭=M|載具名稱|載具號碼|發票日期|商店統編|商店店名|發票號碼|總金額|發票狀態|'
    ) &&
    lines[1].includes('明細=D|發票號碼|小計|品項名稱|') &&
    lines.length > 2,
  '2018-07&08': lines => lines[0].indexOf('M|開立|') === 0 || lines[0].indexOf('M|作廢|') === 0
}

const pushInvoiceObject = ({to, ...newInvoice}) => {
  const {
    number,
    ClassName,
    Code,
    DateTime,
    SellerBan,
    SellerName,
    Amount,
    Status
  } = newInvoice
  return Object.assign({}, to, {
    [number]: {
      ClassName,
      Code,
      DateTime: wrapDate(DateTime),
      SellerBan,
      SellerName,
      number,
      Amount: Number(Amount) || 0,
      Status,
      Details: []
    }
  })
}

const getInvoiceObject = ({lines = [], linesSplit = []}) => {
  let hasError = false
  let invoiceObject = {}
  let message = ''
  if (versionBool['2018-11&12'](lines)) {
    linesSplit.splice(2).forEach(it => {
      if (it[0] === 'M') {
        const [
          ,
          ClassName,
          Code,
          DateTime,
          SellerBan,
          SellerName,
          number,
          Amount ,
          Status
        ] = it
        invoiceObject = pushInvoiceObject({
          to: invoiceObject,
          ClassName,
          Code,
          DateTime,
          SellerBan,
          SellerName,
          number,
          Amount,
          Status
        })
      } else if (it[0] === 'D') {
        const [_, invNumber, Amount, Description, __] = it
        invoiceObject[invNumber].Details.push({
          Amount: Number(Amount) || 0,
          Description
        })
      } else {
        hasError = true
        message = '處理檔案時發生錯誤應為D或M'
      }
    })
  } else if (versionBool['2018-07&08'](lines)) {
    linesSplit.forEach(it => {
      if (it[0] === 'M') {
        const [
          ,
          Status,
          number,
          DateTime,
          SellerBan,
          SellerName,
          ClassName,
          Code,
          Amount
        ] = it
        invoiceObject = pushInvoiceObject({
          to: invoiceObject,
          ClassName,
          Code,
          DateTime,
          SellerBan,
          SellerName,
          number,
          Amount,
          Status
        })
      } else if (it[0] === 'D') {
        const [_, invNumber, Amount, Description] = it
        invoiceObject[invNumber].Details.push({
          Amount: Number(Amount) || 0,
          Description
        })
      } else {
        hasError = true
        message = '處理檔案時發生錯誤應為D或M'
      }
    })
  }

  return {
    obj: invoiceObject,
    hasError,
    message: hasError
      ? message
      : `共有${
          Object.values(invoiceObject).length
        }張發票，總金額為${Object.values(invoiceObject).reduce(
          (sum, current) => (sum += parseFloat(current.Amount)),
          0
        )}元，檢查完畢請按上傳`
  }
}

export {readAllLine, getInvoiceObject}
