import React, {useEffect} from 'react'
import {BrowserRouter, Route} from 'react-router-dom'
import back from './back/index'
import front from './front/index'

const App = () => { 
  return (
    <BrowserRouter>
      <Route path="/" component={front} />
      <Route path="/back" component={back} />
    </BrowserRouter>
  )
}

export default App
