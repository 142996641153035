import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Button from 'react-bootstrap/Button'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { loginUser } from '../share/loginUser'
const Home = props => {
  return <Jumbotron>
    <Row className="justify-content-md-center">
      <Col md="auto">
        <h1>MyInvoice</h1>
      </Col>
    </Row>
    <Row className="justify-content-md-center">
      <Col md="auto">
        <p>此網站可以妥善儲存發票、查詢發票、分析發票</p>
      </Col>
    </Row>
    <Row className="justify-content-md-center">
      <Col md="auto">
        <Button as={Link} to="/front/Login" variant="outline-primary" block>
          立即登入
        </Button>
        <Button as={Link} to="/front/Signup" variant="outline-secondary" block>
          註冊
        </Button>     
      </Col>
    </Row>
  </Jumbotron>
}

export default Home
