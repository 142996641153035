import React from 'react'
import ReactDOM from 'react-dom'
import { Route } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import List from './List'
import Map from './Map'
import WinList from './WinList'
import CardList from './CardList'
import HistoryList from './HistoryList'
import RecentList from './RecentList'
import Import from './Import'
import APISetting from './APISetting'
import Notification from './Notification'
import Report from './Report'
import Chart from './Chart'
import Bar from './Bar'
import ChangePassword from './ChangePassword'
import SellerLocation from './SellerLocation'
import UpdateSellerLocation from './UpdateSellerLocation'
const back = () => (
  <>
    <Bar />
      <Route path="/back" exact component={List} />
      <Route path="/back/List" component={List} />
      <Route path="/back/Map" component={Map} />
      <Route path="/back/WinList" component={WinList} />
      <Route path="/back/CardList" component={CardList} />
      <Route path="/back/HistoryList" component={HistoryList} />
      <Route path="/back/APISetting" component={APISetting} />
      <Route path="/back/Notification" component={Notification} />
      <Route path="/back/RecentList" component={RecentList} />
      <Route path="/back/Import" component={Import} />
      <Route path="/back/Chart" component={Chart} />
      <Route path="/back/Report" component={Report} />
      <Route path="/back/ChangePassword" component={ChangePassword} />  
      <Route path="/back/SellerLocation" component={SellerLocation} />  
      <Route path="/back/UpdateSellerLocation" component={UpdateSellerLocation} />  
  </>
)
export default back
