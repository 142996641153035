import React, { useEffect, useState } from 'react'
import {
  invoiceStatus,
  dateFormat,
  trimCardCode,
  trimCardName,
  trimSellerName
} from '../share/format'
import { agent } from '../share/api'
import { loginUser } from '../share/loginUser'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Pagination from 'react-bootstrap/Pagination'
import PageItem from 'react-bootstrap/PageItem'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import logo from '../logo.svg'
import '../App.css'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import moment from 'moment';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

const initalSearchState = {
  "nowPage": 1,
  "pageSize": 50,
  "sortField": "FULL_ADDR",
  "sortAction": "DESC",
  "openSearchMore": true,
  "byField": '0',
  "byKeyword": "",
  "found": '1',
}

const SearchComponent = ({ setSearchOptions, prizeDates }) => {
  const [prepareQuery, setPrepareQuery] = useState(initalSearchState)

  const handleInputChange = event => {
    const { name, value } = event.target
    setPrepareQuery({ ...prepareQuery, [name]: value })
  }

  return (
    <Form onSubmit={event => event.preventDefault()}>
      <Form.Row>
        <Col xs={6} md={4} lg={2}>
          <Form.Label>欄位之關鍵字</Form.Label>
          <Form.Control
            type="text"
            name="byKeyword"
            placeholder="請輸入關鍵字"
            value={prepareQuery.byKeyword}
            onChange={handleInputChange}
          />
        </Col>
        <Col xs={6} md={4} lg={2}>
          <Form.Label>只列找到</Form.Label>
          <Form.Control
            as="select"
            name="found"
            value={prepareQuery.found}
            onChange={handleInputChange}>
            <option value="0">不拘</option>
            <option value="1">是</option>
            <option value="2">否</option>
          </Form.Control>
        </Col>
      </Form.Row>
      <hr />
      <Form.Row>
        <Col sm>
          <Button
            onClick={() => {
              setSearchOptions(prepareQuery)
            }}>
            查詢
          </Button>
          <Button
            onClick={() => {
              setSearchOptions(initalSearchState)
              setPrepareQuery(initalSearchState)
            }}>
            列出全部
          </Button>
        </Col>
      </Form.Row>
    </Form>
  )
}

const TableComponent = ({ data, searchOptions, setSearchOptions }) => {

  const sortIcon = {
    DESC: '▼',
    ASC: '▲',
    NONE: '◇'
  }
  const tableHeadClick = sortField => {
    const sortAction = 'DESC'
    const action = searchOptions.sortAction === 'DESC' ? 'ASC' : 'DESC'
    setSearchOptions(
      Object.assign({}, searchOptions, { sortField }, { sortAction: action })
    )
  }
  const withSortIcon = sortField =>
    searchOptions.sortField == sortField
      ? sortIcon[searchOptions.sortAction]
      : sortIcon.NONE

  const serialNumberStart = (searchOptions.nowPage - 1) * searchOptions.pageSize

  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>序號</th>
          <th onClick={ev => tableHeadClick('SellerBan')}>統編<small>{withSortIcon('SellerBan')}</small></th>
          <th onClick={ev => tableHeadClick('SellerName')}>發票開立賣方名稱<small>{withSortIcon('SellerName')}</small></th>
          <th onClick={ev => tableHeadClick('SellerAddress')}>地址<small>{withSortIcon('SellerAddress')}</small></th>
          <th onClick={ev => tableHeadClick('FULL_ADDR')}>解析地址<small>{withSortIcon('FULL_ADDR')}</small></th>
          <th onClick={ev => tableHeadClick('G0VAddress')}>G0V地址<small>{withSortIcon('G0VAddress')}</small></th>         
          <th onClick={ev => tableHeadClick('COUNTY')} >縣市<small>{withSortIcon('COUNTY')}</small></th>
          <th onClick={ev => tableHeadClick('TOWN')} >鄉鎮市區<small>{withSortIcon('TOWN')}</small></th>
          <th onClick={ev => tableHeadClick('VILLAGE')} >村里<small>{withSortIcon('VILLAGE')}</small></th>
          <th onClick={ev => tableHeadClick('X')}>X<small>{withSortIcon('X')}</small></th>
          <th onClick={ev => tableHeadClick('Y')}>Y<small>{withSortIcon('Y')}</small></th>
        </tr>
      </thead>
      <tbody>
        {data.map((it, index) => {
          return <tr key={it.SellerBan}>
            <td>{serialNumberStart + index + 1}</td>
            <td>{it.SellerBan}</td>
            <td>{trimSellerName(it.SellerName)}</td>
            <td>{it.SellerAddress}</td>
            <td>{it.FULL_ADDR}</td>
            <td>{it.G0VAddress}</td>           
            <td>{it.COUNTY}</td>
            <td>{it.TOWN}</td>
            <td>{it.VILLAGE}</td>
            <td>{it.X}</td>
            <td>{it.Y}</td>
          </tr>
        })}
      </tbody>
    </Table>
  )
}

const Pager = ({ totalPage, setSearchOptions, searchOptions }) => {
  const handlePageClick = page => {
    setSearchOptions({ ...searchOptions, nowPage: page })
  }
  const currentPage = parseInt(searchOptions.nowPage)
  const firstPage = currentPage > 10 ? currentPage - (currentPage % 10) : 1
  const totalPageItem = totalPage < 11 ? totalPage : 10
  return (
    <Pagination>
      <Pagination.First onClick={() => handlePageClick(1)} />
      <Pagination.Prev
        onClick={() => handlePageClick(currentPage === 1 ? 1 : currentPage - 1)}
      />
      {[...Array(totalPageItem)].map((_, index) => {
        const page = firstPage + index
        if (page <= totalPage) {
          return (
            <Pagination.Item
              key={page}
              onClick={() => handlePageClick(page)}
              active={currentPage === page}>
              {page}
            </Pagination.Item>
          )
        }
      })}
      <Pagination.Next
        onClick={() =>
          handlePageClick(
            totalPage == currentPage ? currentPage : currentPage + 1
          )
        }
      />
      <Pagination.Last onClick={() => handlePageClick(totalPage)} />
    </Pagination>
  )
}

const fetchSellerLocation = searchOptions =>
  Promise.all(
    [fetch(agent.GetNotFoundLocationListIndex, {
      body: JSON.stringify(searchOptions),
      headers: {
        'user-agent': 'Mozilla/4.0 MDN Example',
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + loginUser().Token
      },
      method: 'POST'
    })]).then(response => Promise.all(response.map(it => it.json())))

const SellerLocation = () => {
  const [invoice, setInvoice] = useState([])
  const [prizeDates, setPrizeDates] = useState([]);
  const [searchOptions, setSearchOptions] = useState(initalSearchState)

  useEffect(() => {
    setInvoice(
      Object.assign({}, invoice, { isSuccess: false, message: '查詢中...' })
    )
    fetchSellerLocation(searchOptions)
      .then(([query]) => {

        const totalPage = Math.ceil(query.Data.TotalItemCount / searchOptions.pageSize)
        return setInvoice(
          Object.assign({}, { collection: query.Data.Rows, isSuccess: query.IsSuccess, TotalItemCount: query.Data.TotalItemCount, totalPage })
        )
      })
      .catch(({ message }) => {
        setInvoice(Object.assign({}, { isSuccess: false, message }))
      })
  }, [searchOptions])

  return (
    <Col>
      <SearchComponent setSearchOptions={setSearchOptions} prizeDates={prizeDates} />
      {invoice.isSuccess ? (
        <div>
          <TableComponent
            data={invoice.collection}
            searchOptions={searchOptions}
            setSearchOptions={setSearchOptions}
          />
          共{invoice.TotalItemCount}筆資料 第{searchOptions.nowPage}頁/共
          {invoice.totalPage}頁
          <Pager
            totalPage={invoice.totalPage}
            setSearchOptions={setSearchOptions}
            searchOptions={searchOptions}
          />
        </div>
      ) : (
        <div>{invoice.message || '載入中...'}</div>
      )}
    </Col>
  )
}

export default SellerLocation
