import React, { useEffect, useState } from 'react'
import {
  invoiceStatus,
  dateFormat,
  trimCardCode,
  trimCardName,
  trimSellerName
} from '../share/format'
import { agent } from '../share/api'
import { loginUser } from '../share/loginUser'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Pagination from 'react-bootstrap/Pagination'
import PageItem from 'react-bootstrap/PageItem'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import logo from '../logo.svg'
import '../App.css'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import moment from 'moment';

const initalSearchState = {
  "nowPage": 1,
  "pageSize": 50,
  "sortField": "DateTime",
  "sortAction": "DESC",
  "openSearchMore": true,
  "byField": 0,
  "byKeyword": "",
  "dateTimeS": '',
  "dateTimeE": '',
  "amountS": 0,
  "amountE": 0
}

function MydModalWithGrid(props) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Using Grid in Modal
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <Row>
            <Col xs={12} md={8}>
              .col-xs-12 .col-md-8
            </Col>
            <Col xs={6} md={4}>
              .col-xs-6 .col-md-4
            </Col>
          </Row>

          <Row>
            <Col xs={6} md={4}>
              .col-xs-6 .col-md-4
            </Col>
            <Col xs={6} md={4}>
              .col-xs-6 .col-md-4
            </Col>
            <Col xs={6} md={4}>
              .col-xs-6 .col-md-4
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const SearchComponent = ({ setSearchOptions }) => {
  const [prepareQuery, setPrepareQuery] = useState(initalSearchState)
  const [modalShow, setModalShow] = useState(false);


  const handleInputChange = event => {
    const { name, value } = event.target
    setPrepareQuery({ ...prepareQuery, [name]: value })
  }

  return (
    <Form onSubmit={event => event.preventDefault()}>
      <Form.Row>
        <Col xs={6} md={4} lg={2}>
          <Form.Label>依欄位</Form.Label>
          <Form.Control
            as="select"
            name="byField"
            value={prepareQuery.byField}
            onChange={handleInputChange}>
            <option value="0">不拘</option>
            <option value="1">品名</option>
            <option value="2">店家</option>
            <option value="3">地址</option>
            <option value="4">賣方統編</option>
            <option value="5">載具隱碼</option>
          </Form.Control>
        </Col>
        <Col xs={6} md={4} lg={2}>
          <Form.Label>欄位之關鍵字</Form.Label>
          <Form.Control
            type="text"
            name="byKeyword"
            placeholder="請輸入關鍵字"
            value={prepareQuery.byKeyword}
            onChange={handleInputChange}
          />
        </Col>
        <Col xs={6} md={4} lg={2}>
          <Form.Label>價格大於</Form.Label>
          <Form.Control
            type="number"
            name="amountS"
            step="1"
            min="0"
            placeholder="請輸入金額"
            value={prepareQuery.amountS}
            onChange={handleInputChange}
          />
        </Col>
        <Col xs={6} md={4} lg={2}>
          <Form.Label>價格小於</Form.Label>
          <Form.Control
            type="number"
            name="amountE"
            step="1"
            min="0"
            placeholder="請輸入金額"
            value={prepareQuery.amountE}
            onChange={handleInputChange}
          />
        </Col>
        <Col xs={6} md={4} lg={2}>
          <Form.Label>發票開始日期</Form.Label>
          <Form.Control
            type="date"
            name="dateTimeS"
            value={prepareQuery.dateTimeS}
            onChange={handleInputChange}
          />
        </Col>
        <Col xs={6} md={4} lg={2}>
          <Form.Label>發票結束日期</Form.Label>
          <Form.Control
            type="date"
            name="dateTimeE"
            value={prepareQuery.dateTimeE}
            onChange={handleInputChange}
          />
        </Col>
      </Form.Row>
      <hr />
      <Form.Row>
        <Col sm>
          <Button
            onClick={() => {
              setSearchOptions(prepareQuery)
            }}>
            查詢
          </Button>
          <Button
            onClick={() => {
              setSearchOptions(initalSearchState)
              setPrepareQuery(initalSearchState)
            }}>
            列出全部
          </Button>
          {/* <Button variant="primary" onClick={() => setModalShow(true)}>
            Launch modal with grid
          </Button> */}
          {/* <MydModalWithGrid show={modalShow} onHide={() => setModalShow(false)} /> */}
        </Col>
      </Form.Row>
    </Form>
  )
}

const TableComponent = ({ data, searchOptions, setSearchOptions }) => {

  const sortIcon = {
    DESC: '▼',
    ASC: '▲',
    NONE: '◇'
  }
  const tableHeadClick = sortField => {
    const sortAction = 'DESC'
    const action = searchOptions.sortAction === 'DESC' ? 'ASC' : 'DESC'
    setSearchOptions(
      Object.assign({}, searchOptions, { sortField }, { sortAction: action })
    )
  }
  const withSortIcon = sortField =>
    searchOptions.sortField == sortField
      ? sortIcon[searchOptions.sortAction]
      : sortIcon.NONE

  const serialNumberStart = (searchOptions.nowPage - 1) * searchOptions.pageSize
  const detailText = ({ Description, Amount, Quantity = '', UnitPrice = '' }) => {
    if (UnitPrice == null && Quantity == null) {
      return Description + '(' + Amount + ')'
    }

    if (Quantity === 1 && UnitPrice === Amount) {
      return Description + '(' + Amount + ')'
    }

    return Description + '(' + UnitPrice + '*' + Quantity + '=' + Amount + ')'
  }

  return (
    <Table size="sm">
      <thead>
        <tr>
          <th style={{ width: "5%" }}>序號</th>
          <th onClick={ev => tableHeadClick('Status')} style={{ width: "10%" }}>狀態<small>{withSortIcon('Status')}</small></th>
          <th onClick={ev => tableHeadClick('Number')} style={{ width: "15%" }}>號碼<small>{withSortIcon('Number')}</small></th>
          <th onClick={ev => tableHeadClick('DateTime')} style={{ width: "15%" }}>日期<small>{withSortIcon('DateTime')}</small></th>
          <th onClick={ev => tableHeadClick('SellerBan')} style={{ width: "15%" }}>統編<small>{withSortIcon('SellerBan')}</small></th>
          <th onClick={ev => tableHeadClick('CardCode')} style={{ width: "30%" }}>載具內碼<small>{withSortIcon('CardCode')}</small></th>
          <th onClick={ev => tableHeadClick('Amount')} style={{ width: "20%" }}>金額<small>{withSortIcon('Amount')}</small></th>
        </tr>
      </thead>
      {data.map((it, index) => {
        const color = index % 2 === 0 ? '#F5F5F5' : '#ffffFF'
        return <tbody key={it.EInvoiceID} style={{ 'backgroundColor': color }}>
          <tr>
            <td rowSpan="3">{serialNumberStart + index + 1}</td>
            <td>{it.DonateMark === 1 ? "已捐贈" : it.Status}</td>
            <td>{it.Number}</td>
            <td>{dateFormat(it.DateTime)}</td>
            <td>{it.SellerBan}</td>
            <td>{trimCardCode(it.CardCode)}</td>
            <td>{it.Amount}</td>
          </tr>
          <tr><td colSpan="7" style={{ "textAlign": "center" }}>{it.SellerName}</td></tr>
          <tr><td colSpan="7">{it.Details.map(d => detailText(d)).join('、')}</td></tr>
        </tbody>
      })}
    </Table>
  )
}

const Pager = ({ totalPage, setSearchOptions, searchOptions }) => {
  const handlePageClick = page => {
    setSearchOptions({ ...searchOptions, nowPage: page })
  }
  const currentPage = parseInt(searchOptions.nowPage)
  const firstPage = currentPage > 10 ? currentPage - (currentPage % 10) : 1
  const totalPageItem = totalPage < 11 ? totalPage : 10
  return (
    <Pagination>
      <Pagination.First onClick={() => handlePageClick(1)} />
      <Pagination.Prev
        onClick={() => handlePageClick(currentPage === 1 ? 1 : currentPage - 1)}
      />
      {[...Array(totalPageItem)].map((_, index) => {
        const page = firstPage + index
        if (page <= totalPage) {
          return (
            <Pagination.Item
              key={page}
              onClick={() => handlePageClick(page)}
              active={currentPage === page}>
              {page}
            </Pagination.Item>
          )
        }
      })}
      <Pagination.Next
        onClick={() =>
          handlePageClick(
            totalPage == currentPage ? currentPage : currentPage + 1
          )
        }
      />
      <Pagination.Last onClick={() => handlePageClick(totalPage)} />
    </Pagination>
  )
}

const fetchInvoice = searchOptions =>
  Promise.all(
    [agent.GetListIndex, agent.GetListStatus].map(url =>
      fetch(url, {
        body: JSON.stringify(searchOptions),
        headers: {
          'user-agent': 'Mozilla/4.0 MDN Example',
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + loginUser().Token
        },
        method: 'POST'
      })
    )
  ).then(response => Promise.all(response.map(it => it.json())))

const List = () => {
  const [invoice, setInvoice] = useState([])
  const [status, setStatus] = useState({})
  const [searchOptions, setSearchOptions] = useState(initalSearchState)

  useEffect(() => {
    setInvoice(
      Object.assign({}, invoice, { isSuccess: false, message: '查詢中...' })
    )
    fetchInvoice(searchOptions)
      .then(([query, status]) => {
        setStatus(status.Data)
        const totalPage = Math.ceil(query.Data.TotalItemCount / searchOptions.pageSize)
        return setInvoice(
          Object.assign({}, { collection: query.Data.Rows, isSuccess: query.IsSuccess, TotalItemCount: query.Data.TotalItemCount, totalPage })
        )
      })
      .catch(({ message }) => {
        setInvoice(Object.assign({}, { isSuccess: false, message }))
      })
  }, [searchOptions])

  return (
    <Col>
      <SearchComponent setSearchOptions={setSearchOptions} />
      {invoice.isSuccess ? (
        <div>
          <TableComponent
            data={invoice.collection}
            searchOptions={searchOptions}
            setSearchOptions={setSearchOptions}
          />
          共{invoice.TotalItemCount}筆資料 第{searchOptions.nowPage}頁/共
          {invoice.totalPage}頁 總金額：{status.TotalAmount} 平均每張發票
          {status.AvgAmount}元
          <Pager
            totalPage={invoice.totalPage}
            setSearchOptions={setSearchOptions}
            searchOptions={searchOptions}
          />
        </div>
      ) : (
        <div>{invoice.message || '載入中...'}</div>
      )}
    </Col>
  )
}

export default List
