import React, { useEffect, useState } from 'react'
import {
  invoiceStatus,
  dateFormat,
  trimCardCode,
  trimCardName,
  trimSellerName
} from '../share/format'
import { agent } from '../share/api'
import { loginUser } from '../share/loginUser'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Pagination from 'react-bootstrap/Pagination'
import PageItem from 'react-bootstrap/PageItem'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import logo from '../logo.svg'
import '../App.css'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import moment from 'moment';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

const initalSearchState = {
  "nowPage": 1,
  "pageSize": 50,
  "sortField": "DateTime",
  "sortAction": "DESC",
  "openSearchMore": true,
  "byField": '0',
  "byKeyword": "",
  "dateTimeS": '',
  "dateTimeE": '',
  "onlyShowWin": '1',
  "winNumberType": '0'
}

const winNumberTypes = {
  1: '一般',
  2: '雲端發票',
}



const SearchComponent = ({ setSearchOptions, prizeDates }) => {
  const [prepareQuery, setPrepareQuery] = useState(initalSearchState)
  const [modalShow, setModalShow] = useState(false);

  const handleInputChange = event => {
    const { name, value } = event.target
    setPrepareQuery({ ...prepareQuery, [name]: value })
  }

  const CanPrizeListModal = (props) => {
    const [prizeList, setPrizeList] = useState(prizeDates.map((it) => ({ date: it, isDisabled: false, btnText: '對獎' })))

    const doPrize = (index) => {
      let items = [...prizeList];
      let item = { ...items[index] };
      item.isDisabled = true;
      items[index] = item;
      setPrizeList(items);
      fetch(agent.DoPrize, {
        body: JSON.stringify({date : item.date}),
        headers: {
          'user-agent': 'Mozilla/4.0 MDN Example',
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + loginUser().Token
        },
        method: 'POST'
      }).then(rs => rs.json())
        .then(rsJson => {
          alert(rsJson.Message)
        })
    }
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            可供重對過去匯入發票列表
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>序號</th>
                <th>開獎日期</th>
                <th>對獎</th>
              </tr>
            </thead>
            <tbody>
              {prizeList.length > 0?  prizeList.map((it, index) => <tr key={index}>
                <td>{index + 1}</td>
                <td>{it.date}</td>
                <td>
                  <Button variant="secondary" disabled={it.isDisabled} onClick={() => doPrize(index)}>{it.btnText}</Button>
                </td>
              </tr>) :  <tr><td>太好了</td>所有發票<td>都對完了</td><td></td></tr>}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>關閉</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Form onSubmit={event => event.preventDefault()}>
      <Form.Row>
        <Col xs={6} md={4} lg={2}>
          <Form.Label>只列中獎</Form.Label>
          <Form.Control
            as="select"
            name="onlyShowWin"
            value={prepareQuery.onlyShowWin}
            onChange={handleInputChange}>
            <option value="0">不拘</option>
            <option value="1">是</option>
            <option value="2">否</option>
          </Form.Control>
        </Col>
        <Col xs={6} md={4} lg={2}>
          <Form.Label>獎項類型</Form.Label>
          <Form.Control
            as="select"
            name="winNumberType"
            value={prepareQuery.winNumberType}
            onChange={handleInputChange}>
            <option value="0">不拘</option>
            <option value="1">一般</option>
            <option value="2">雲端發票</option>
          </Form.Control>
        </Col>

        <Col xs={6} md={4} lg={2}>
          <Form.Label>發票開始日期</Form.Label>
          <Form.Control
            type="date"
            name="dateTimeS"
            value={prepareQuery.dateTimeS}
            onChange={handleInputChange}
          />
        </Col>
        <Col xs={6} md={4} lg={2}>
          <Form.Label>發票結束日期</Form.Label>
          <Form.Control
            type="date"
            name="dateTimeE"
            value={prepareQuery.dateTimeE}
            onChange={handleInputChange}
          />
        </Col>
      </Form.Row>
      <hr />
      <Form.Row>
        <Col sm>
          <Button
            onClick={() => {
              setSearchOptions(prepareQuery)
            }}>
            查詢
          </Button>
          <Button
            onClick={() => {
              setSearchOptions(initalSearchState)
              setPrepareQuery(initalSearchState)
            }}>
            列出全部
          </Button>
          <Button variant="primary" onClick={() => setModalShow(true)}>
            重對過去匯入發票
          </Button>
          <CanPrizeListModal show={modalShow} onHide={() => setModalShow(false)} />
        </Col>
      </Form.Row>
    </Form>
  )
}

const TableComponent = ({ data, searchOptions, setSearchOptions }) => {

  const sortIcon = {
    DESC: '▼',
    ASC: '▲',
    NONE: '◇'
  }
  const tableHeadClick = sortField => {
    const sortAction = 'DESC'
    const action = searchOptions.sortAction === 'DESC' ? 'ASC' : 'DESC'
    setSearchOptions(
      Object.assign({}, searchOptions, { sortField }, { sortAction: action })
    )
  }
  const withSortIcon = sortField =>
    searchOptions.sortField == sortField
      ? sortIcon[searchOptions.sortAction]
      : sortIcon.NONE

  const serialNumberStart = (searchOptions.nowPage - 1) * searchOptions.pageSize
  const detailText = ({ Description, Amount, Quantity = '', UnitPrice = '' }) => {
    if (UnitPrice == null && Quantity == null) {
      return Description + '(' + Amount + ')'
    }

    if (Quantity === 1 && UnitPrice === Amount) {
      return Description + '(' + Amount + ')'
    }

    return Description + '(' + UnitPrice + '*' + Quantity + '=' + Amount + ')'
  }

  return (
    <Table size="sm">
      <thead>
        <tr>
          <th style={{ width: "5%" }}>序號</th>
          <th onClick={ev => tableHeadClick('DateTime')} style={{ width: "15%" }}>日期<small>{withSortIcon('DateTime')}</small></th>
          <th onClick={ev => tableHeadClick('Number')} style={{ width: "10%" }}>號碼<small>{withSortIcon('Number')}</small></th>
          <th onClick={ev => tableHeadClick('Period')} style={{ width: "10%" }}>開獎期別<small>{withSortIcon('Period')}</small></th>
          <th onClick={ev => tableHeadClick('WinNumberType')} style={{ width: "20%" }}>獎項類型<small>{withSortIcon('WinNumberType')}</small></th>
          <th onClick={ev => tableHeadClick('Amount')} style={{ width: "10%" }}>發票金額<small>{withSortIcon('Amount')}</small></th>
          <th onClick={ev => tableHeadClick('Gap')} style={{ width: "10%" }}>殘念順序<small>{withSortIcon('Amount')}</small></th>
          <th onClick={ev => tableHeadClick('Money')} style={{ width: "10%" }}>獎項金額<small>{withSortIcon('Amount')}</small></th>
          <th onClick={ev => tableHeadClick('WinMoney')} style={{ width: "10%" }}>實中金額<small>{withSortIcon('WinMoney')}</small></th>
        </tr>
      </thead>
      {data.map((it, index) => {
        const color = index % 2 === 0 ? '#F5F5F5' : '#ffffFF'
        const fontWeight = it.Gap === 0 ? '500' : 'normal'
        return <tbody key={it.WinInvoiceID} style={{ 'backgroundColor': color, 'fontWeight': fontWeight }}>
          <tr>
            <td>{serialNumberStart + index + 1}</td>
            <td>{dateFormat(it.DateTime)}</td>
            <td>{it.Number}{it.DonateMark === 1 ? "(已捐贈)" : ""}</td>
            <td>{it.Period}</td>
            <td>{winNumberTypes[it.WinNumberType]}</td>
            <td>{it.Amount}</td>
            <td>{it.Gap}</td>
            <td>{it.Money}</td>
            <td>{it.WinMoney}</td>
          </tr>
          <tr><td colSpan="9" style={{ "textAlign": "center" }}>{it.SellerName}</td></tr>
          <tr><td colSpan="9">{it.Details.map(d => detailText(d)).join('、')}</td></tr>
        </tbody>
      })}
    </Table>
  )
}

const Pager = ({ totalPage, setSearchOptions, searchOptions }) => {
  const handlePageClick = page => {
    setSearchOptions({ ...searchOptions, nowPage: page })
  }
  const currentPage = parseInt(searchOptions.nowPage)
  const firstPage = currentPage > 10 ? currentPage - (currentPage % 10) : 1
  const totalPageItem = totalPage < 11 ? totalPage : 10
  return (
    <Pagination>
      <Pagination.First onClick={() => handlePageClick(1)} />
      <Pagination.Prev
        onClick={() => handlePageClick(currentPage === 1 ? 1 : currentPage - 1)}
      />
      {[...Array(totalPageItem)].map((_, index) => {
        const page = firstPage + index
        if (page <= totalPage) {
          return (
            <Pagination.Item
              key={page}
              onClick={() => handlePageClick(page)}
              active={currentPage === page}>
              {page}
            </Pagination.Item>
          )
        }
      })}
      <Pagination.Next
        onClick={() =>
          handlePageClick(
            totalPage == currentPage ? currentPage : currentPage + 1
          )
        }
      />
      <Pagination.Last onClick={() => handlePageClick(totalPage)} />
    </Pagination>
  )
}

const fetchInvoice = searchOptions =>
  Promise.all(
    [fetch(agent.GetWinListIndex, {
      body: JSON.stringify(searchOptions),
      headers: {
        'user-agent': 'Mozilla/4.0 MDN Example',
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + loginUser().Token
      },
      method: 'POST'
    }),
    fetch(agent.GetCanPrizeList, {
      headers: {
        'user-agent': 'Mozilla/4.0 MDN Example',
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + loginUser().Token
      },
      method: 'GET'
    })
    ]).then(response => Promise.all(response.map(it => it.json())))

const WinList = () => {
  const [invoice, setInvoice] = useState([])
  const [prizeDates, setPrizeDates] = useState([]);
  const [searchOptions, setSearchOptions] = useState(initalSearchState)

  useEffect(() => {
    setInvoice(
      Object.assign({}, invoice, { isSuccess: false, message: '查詢中...' })
    )
    fetchInvoice(searchOptions)
      .then(([query, canPrizeList]) => {

        setPrizeDates(canPrizeList.Data)
        const totalPage = Math.ceil(query.Data.TotalItemCount / searchOptions.pageSize)
        return setInvoice(
          Object.assign({}, { collection: query.Data.Rows, isSuccess: query.IsSuccess, TotalItemCount: query.Data.TotalItemCount, totalPage })
        )
      })
      .catch(({ message }) => {
        setInvoice(Object.assign({}, { isSuccess: false, message }))
      })
  }, [searchOptions])

  return (
    <Col>
      <SearchComponent setSearchOptions={setSearchOptions} prizeDates={prizeDates} />
      {invoice.isSuccess ? (
        <div>
          <TableComponent
            data={invoice.collection}
            searchOptions={searchOptions}
            setSearchOptions={setSearchOptions}
          />
          共{invoice.TotalItemCount}筆資料 第{searchOptions.nowPage}頁/共
          {invoice.totalPage}頁
          <Pager
            totalPage={invoice.totalPage}
            setSearchOptions={setSearchOptions}
            searchOptions={searchOptions}
          />
        </div>
      ) : (
        <div>{invoice.message || '載入中...'}</div>
      )}
    </Col>
  )
}

export default WinList
