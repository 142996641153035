const loginUser = () => {
  const d = localStorage.getItem('d')
  if (d) {

    const parseResult = JSON.parse(d)
    if (parseResult.IsSuccess && parseResult.Data) {
      return parseResult.Data
    }
  }

  return {
    "UserID": 0,
    "Name": "",
    "Email": "",
    "Token": ""
  }
}
export { loginUser }
