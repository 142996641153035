import React, { useEffect, useReducer, useState } from 'react'
import {
  invoiceStatus,
  dateFormat,
  trimCardCode,
  trimCardName,
  trimSellerName
} from '../share/format'
import { agent } from '../share/api'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import PageItem from 'react-bootstrap/PageItem'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import logo from '../logo.svg'
import '../App.css'

const initalSearchState = {
  take: 10,
  PageSize: '50',
  NowPage: '1',
  ByKeyword: '',
  ByUserID: 'f9a7ac04-97f3-4320-8b34-7c5bb0f15404',
  sortAction: 'DESC',
  sortField: 'invDateTime'
}

const SearchComponent = ({ setSearchOptions }) => {
  const [prepareQuery, setPrepareQuery] = useState(initalSearchState)
  const handleInputChange = event => {
    const { name, value } = event.target
    setPrepareQuery({ ...prepareQuery, [name]: value })
  }

  return (
    <Form onSubmit={event => event.preventDefault()}>
      <Form.Row>
        <Col>
        <Form.Label>筆數</Form.Label>
          <Form.Control
            type="text"
            name="take"
            value={prepareQuery.take}
            onChange={handleInputChange}
          />
        </Col>
        <Col>
          <Form.Label>用戶</Form.Label>
          <Form.Control
            as="select"
            name="ByUserID"
            value={prepareQuery.ByUserID}
            onChange={handleInputChange}>
            <option value="580777c0-da41-44dd-ba82-22e6615228ac">580777c0-da41-44dd-ba82-22e6615228ac</option>
            <option value="9a4622a8-72ba-483f-92ca-47fc1ab6699d">9a4622a8-72ba-483f-92ca-47fc1ab6699d</option>
            <option value="d8d3da94-3ee1-4d5d-86e6-e0efe3a77253">d8d3da94-3ee1-4d5d-86e6-e0efe3a77253</option>
            <option value="f9a7ac04-97f3-4320-8b34-7c5bb0f15404">f9a7ac04-97f3-4320-8b34-7c5bb0f15404</option>          </Form.Control>
        </Col>
      </Form.Row>     
      <Form.Row md={4}>
        <Col>
        <Form.Label></Form.Label>
          <Button
            onClick={() => {            
              setSearchOptions(prepareQuery)
            }}>
            查詢
          </Button>
          <Button
            onClick={() => {
              setSearchOptions(initalSearchState)
              setPrepareQuery(initalSearchState)
            }}>
            列出全部
          </Button>
        </Col>
      </Form.Row>
    </Form>
  )
}

const popover = invoice => (
  <Popover id="popover-basic" title={invoice.number}>
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>商品名稱</th>
          <th>總金額</th>
        </tr>
      </thead>
      <tbody>
        {invoice.details.map((it, index) => (
          <tr key={index}>
            <td>{it.name}</td>
            <td>{it.amount}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </Popover>
)
const TableComponent = ({ data, searchOptions, setSearchOptions }) => {
  const sortIcon = {
    DESC: '▼',
    ASC: '▲',
    NONE: '◇'
  }
  const tableHeadClick = sortField => {
    const sortAction = 'DESC'
    const action = searchOptions.sortAction === 'DESC' ? 'ASC' : 'DESC'
    setSearchOptions(
      Object.assign({}, searchOptions, { sortField }, { sortAction: action })
    )
  }
  const withSortIcon = sortField =>
    searchOptions.sortField == sortField
      ? sortIcon[searchOptions.sortAction]
      : sortIcon.NONE

  const serialNumberStart = (searchOptions.NowPage - 1) * searchOptions.PageSize
  return (
    <Table striped bordered hover responsive="xl" size="sm">
      <thead>
        <tr>
          <th>序號</th>
          <th>
            狀態
          </th>
          <th onClick={ev => tableHeadClick('invNum')}>
            發票號碼{withSortIcon('invNum')}
          </th>
          <th onClick={ev => tableHeadClick('invDateTime')}>
            日期{withSortIcon('invDateTime')}
          </th>
          <th onClick={ev => tableHeadClick('sellerBan')}>
            統一編號{withSortIcon('sellerBan')}
          </th>
          <th onClick={ev => tableHeadClick('sellerName')}>
            賣方名稱{withSortIcon('sellerName')}
          </th>
          <th onClick={ev => tableHeadClick('cardType')}>
            載具名稱{withSortIcon('cardType')}
          </th>
          <th onClick={ev => tableHeadClick('cardNo')}>
            載具內碼{withSortIcon('cardNo')}
          </th>
          <th onClick={ev => tableHeadClick('amount')}>
            金額{withSortIcon('amount')}
          </th>
          <th>動作</th>
        </tr>
      </thead>
      <tbody>
        {data.map((it, index) => (
          <tr key={it.id}>
            <td>{serialNumberStart + index + 1}</td>
            <td>{invoiceStatus[it.status]}</td>
            <td>{it.number}</td>
            <td>{dateFormat(it.date)}</td>
            <td>{it.sellerBan}</td>
            <td>{trimSellerName(it.sellerName)}</td>
            <td>{trimCardName(it.cardName)}</td>
            <td>{trimCardCode(it.cardCode)}</td>
            <td>{it.money}</td>
            <td>
              <OverlayTrigger
                trigger={['hover', 'focus', 'click']}
                placement="left-start"
                overlay={popover(it)}>
                <Button variant="info">明細</Button>
              </OverlayTrigger>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const RecentList = () => {
  const [data, setData] = useState([])
  const [searchOptions, setSearchOptions] = useState(initalSearchState)


  return <div>
    <SearchComponent setSearchOptions={setSearchOptions} />
    <hr />
    <TableComponent
      data={data}
      searchOptions={searchOptions}
      setSearchOptions={setSearchOptions}
    />
    共{data.length}筆資料 總金額：{data.reduce((sum, it)=> sum+=it.money, 0)} 平均每張發票{data.reduce((sum, it)=> sum+=it.money, 0) / data.length}元
  </div>

}

export default RecentList
