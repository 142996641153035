import React, {useEffect, useState} from 'react'
import {agent} from '../share/api'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Col from 'react-bootstrap/Col'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Alert from 'react-bootstrap/Alert'
import Popover from 'react-bootstrap/Popover'
import Table from 'react-bootstrap/Table'

const fetchProductData = productName =>
  fetch(agent.ProductMoneyInfo + '?productName=' + productName)

const Report = () => {
  const [productName, setProductName] = useState('')
  const [productMonthMoney, setProductMonthMoney] = useState({})
  return (
    <>
      <Form onSubmit={event => event.preventDefault()}>
        <Form.Row>
          <Col>
            <Form.Control
              type="text"
              name="ByKeyword"
              placeholder="請輸入關鍵字"
              value={productName}
              onChange={e => {
                setProductName(e.target.value)
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                fetchProductData(productName)
                .then(response => response.json())
                .then(data => {
                  setProductMonthMoney(data)
                  console.log(data)
                })
              }}>
              查詢
            </Button>
          </Col>
        </Form.Row>
      </Form>
      以下為 {productMonthMoney.productName || ''} 各期所花費金額：
      <Table striped bordered hover responsive="xl" size="sm">
        <thead>
          <tr>
            <th>年月</th>
            <th>金額</th>
          </tr>
        </thead>
        <tbody>
          {productMonthMoney.result &&
            productMonthMoney.result.map(it => (
              <tr key={it.yearMonth}>
                <td>{it.yearMonth}</td>
                <td>{it.money}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  )
}

export default Report
