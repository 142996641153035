import React, { useEffect, useState } from 'react'
import {
  invoiceStatus,
  dateFormat,
  trimCardCode,
  trimCardName,
  trimSellerName,
  wrapDate
} from '../share/format'
import { readAllLine, getInvoiceObject } from './parseCsv'
import { agent } from '../share/api'
import { loginUser } from '../share/loginUser'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Alert from 'react-bootstrap/Alert'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Popover from 'react-bootstrap/Popover'
import Modal from 'react-bootstrap/Modal';


const defaultQueryAddrResponse = { results: [] }

const TableComponent = ({ data, setData }) => {
  const [show, setShow] = useState(false);
  const [modalSelectedItem, setModalSelectedItem] = useState({ APIResponse: defaultQueryAddrResponse });
  const handleClose = () => {
    setShow(false)
  };
  const handleShow = (item) => {
    console.log(item)
    setModalSelectedItem(item);
    setShow(true);
  }
  const handleCopy = (sellerBan) => {
    const updatedData = data.map(item => {
      if (item.SellerBan === sellerBan) {
        return { ...item, oAddress: item.SellerAddress || item.G0VAddress };
      }
      return item;
    });
    setData(updatedData);
  }

  const handleInputChange = (e, sellerBan) => {
    const updatedData = data.map(item => {
      if (item.SellerBan === sellerBan) {
        return { ...item, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value };
      }
      return item;
    });
    setData(updatedData);
  };

  return (
    <>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th style={{ width: '5%' }}>統編</th>
            <th style={{ width: '10%' }}>發票開立賣方名稱</th>
            <th style={{ width: '15%' }}>地址</th>
            <th style={{ width: '15%' }}>G0V地址</th>
            <th style={{ width: '3%' }}>帶入</th>
            <th style={{ width: '25%' }}>更新地址</th>
            <th style={{ width: '5%' }}>完全符合</th>
            <th style={{ width: '8%' }}>上傳結果</th>
            <th style={{ width: '5%' }}>詳細錯誤</th>
          </tr>
        </thead>
        <tbody>
          {data.map((it) => {
            return <tr key={it.SellerBan}>
              <td>{it.SellerBan}</td>
              <td>{trimSellerName(it.SellerName)}</td>
              <td>{it.SellerAddress}</td>
              <td>{it.G0VAddress}</td>
              <td> <Button variant="primary" onClick={() => handleCopy(it.SellerBan)}>
                →
              </Button></td>
              <td>
                <Form.Control
                  type="text"
                  placeholder="更新地址"
                  name="oAddress"
                  onChange={e => handleInputChange(e, it.SellerBan)}
                  value={it.oAddress}
                /></td>
              <td>
                <Form.Control
                  type="checkbox"
                  name="oIsOnlyFullMatch"
                  onChange={e => handleInputChange(e, it.SellerBan)}
                  checked={it.oIsOnlyFullMatch}
                /></td>
              <td>{it.Message}</td>
              <td>
                <Button variant="primary" onClick={() => handleShow(it)}>
                  詳細錯誤
                </Button>
              </td>
            </tr>
          })}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose} animation={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{trimSellerName(modalSelectedItem.SellerName || "")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ overflowX: 'auto' }}>
          <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>srs</th>
                  <th>types</th>
                  <th>featureCount</th>
                  <th>pages</th>
                  <th>page</th>
                  <th>status</th>
                </tr>
              </thead>
              <tbody>               
                  <tr>
                    <td>{modalSelectedItem.APIResponse.srs}</td>
                    <td>{modalSelectedItem.APIResponse.types}</td>
                    <td>{modalSelectedItem.APIResponse.featureCount}</td>
                    <td>{modalSelectedItem.APIResponse.pages}</td>
                    <td>{modalSelectedItem.APIResponse.page}</td>
                    <td>{modalSelectedItem.APIResponse.status}</td>
                  </tr>                
              </tbody>
            </Table>
            模糊位置:
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>FULL_ADDR</th>
                  <th>COUNTY</th>
                  <th>TOWN</th>
                  <th>VILLAGE</th>
                  <th>NEIGHBORHOOD</th>
                  <th>ROAD</th>
                  <th>SECTION</th>
                  <th>LANE</th>
                  <th>ALLEY</th>
                  <th>X</th>
                  <th>Y</th>
                </tr>
              </thead>
              <tbody>
                {modalSelectedItem.APIResponse.results.map((it, index) => (
                  <tr key={index}>
                    <td>{it.FULL_ADDR}</td>
                    <td>{it.COUNTY}</td>
                    <td>{it.TOWN}</td>
                    <td>{it.VILLAGE}</td>
                    <td>{it.NEIGHBORHOOD}</td>
                    <td>{it.ROAD}</td>
                    <td>{it.SECTION}</td>
                    <td>{it.LANE}</td>
                    <td>{it.ALLEY}</td>
                    <td>{it.geometry.x}</td>
                    <td>{it.geometry.y}</td>
                  </tr>
                ))}
              </tbody>
            </Table></div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            關閉
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const UpdateSellerLocation = () => {
  const [preview, setPreview] = useState([])
  const [message, setMessage] = useState({
    isSuccess: false,
    text: '請選擇檔案。'
  })
  useEffect(() => {
    const searchOptions = {
      "nowPage": 1,
      "pageSize": 200,
      "sortField": "FULL_ADDR",
      "sortAction": "DESC",
      "openSearchMore": true,
      "byField": '0',
      "byKeyword": "",
      "found": '2', //1yes 2no
    }

    Promise.all(
      [fetch(agent.GetNotFoundLocationListIndex, {
        body: JSON.stringify(searchOptions),
        headers: {
          'user-agent': 'Mozilla/4.0 MDN Example',
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + loginUser().Token
        },
        method: 'POST'
      })])
      .then(response => Promise.all(response.map(it => it.json())))
      .then(([query]) => {
        const data = query.Data.Rows
        console.log(query.Data)
        setPreview(
          data.map(inv => ({
            ...inv,
            oAddress: '',
            oIsOnlyFullMatch: true,
            Message: '待上傳',
            APIResponse: defaultQueryAddrResponse
          }))
        )
        setMessage({ isSuccess: query.IsSuccess, text: query.Message })
      })
      .catch(({ message }) => {
        alert(message)
      })
  }, [])

  return (
    <>
      <a href='https://map.tgos.tw/TGOSCloud/Web/Map/TGOSViewer_Map.aspx' target="_blank" rel="noreferrer noopenner">圖台</a>
      <button
        disabled={!message.isSuccess}
        onClick={() => {
          const sellerData = Object
            .values(preview)
            .map(p => ({
              SellerBan: p.SellerBan,
              oAddress: p.oAddress,
              oIsOnlyFullMatch: p.oIsOnlyFullMatch
            })) || []

          if (sellerData.length > 0) {
            setMessage(
              Object.assign({}, message, {
                isSuccess: false,
                text: '上傳中...請稍候...'
              })
            )

            fetch(agent.SellerLocationSave, {
              body: JSON.stringify(sellerData),
              headers: {
                'user-agent': 'Mozilla/4.0 MDN Example',
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + loginUser().Token
              },
              method: 'POST'
            })
              .then(response => response.json())
              .then(({ IsSuccess, Message, Data }) => {
                setPreview(
                  preview.map(inv => {
                    const matchBan = Data.find(it => it.SellerBan === inv.SellerBan)
                    return {
                      ...inv,
                      Message: matchBan ? matchBan.Message : '成功！',
                      APIResponse: matchBan ? matchBan.APIResponse : defaultQueryAddrResponse
                    }
                  })
                )
                setMessage({ isSuccess: IsSuccess, text: Message })
              })
          }
        }}>
        更新
      </button>
      {message.isSuccess ? (
        <Alert variant="success">{message.text}</Alert>
      ) : (
        <Alert variant="danger"> {message.text}</Alert>
      )}

      <TableComponent data={preview} setData={setPreview} />
    </>
  )
}

export default UpdateSellerLocation
