import React, { useEffect, useState } from 'react'
import {
  invoiceStatus,
  dateFormat,
  trimCardCode,
  trimCardName,
  trimSellerName
} from '../share/format'
import { agent } from '../share/api'
import { loginUser } from '../share/loginUser'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Pagination from 'react-bootstrap/Pagination'
import '../App.css'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'

const initalSearchState = {
  "nowPage": 1,
  "pageSize": 50,
  "sortField": "DateTime",
  "sortAction": "DESC",
  "openSearchMore": true,
  "byField": 0,
  "byKeyword": "",
}

const TableComponent = ({ data, searchOptions, setSearchOptions }) => {

  const sortIcon = {
    DESC: '▼',
    ASC: '▲',
    NONE: '◇'
  }
  const tableHeadClick = sortField => {
    const sortAction = 'DESC'
    const action = searchOptions.sortAction === 'DESC' ? 'ASC' : 'DESC'
    setSearchOptions(
      Object.assign({}, searchOptions, { sortField }, { sortAction: action })
    )
  }
  const withSortIcon = sortField =>
    searchOptions.sortField == sortField
      ? sortIcon[searchOptions.sortAction]
      : sortIcon.NONE

  const serialNumberStart = (searchOptions.nowPage - 1) * searchOptions.pageSize

  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th style={{width: "5%"}}>序號</th>
          <th onClick={ev => tableHeadClick('Code')} style={{width: "10%"}}>內碼<small>{withSortIcon('Code')}</small></th>
          <th onClick={ev => tableHeadClick('Name')} style={{width: "15%"}}>自訂名稱<small>{withSortIcon('Name')}</small></th>
          <th onClick={ev => tableHeadClick('ClassName')} style={{width: "15%"}}>類別名稱<small>{withSortIcon('ClassName')}</small></th>
          <th onClick={ev => tableHeadClick('ClassCode')} style={{width: "15%"}}>類別代碼<small>{withSortIcon('ClassCode')}</small></th>        
        </tr>
      </thead>
      <tbody>
      {data.map((it, index) => {     
        return <tr key={it.EInvoiceCardID}>
            <td>{serialNumberStart + index + 1}</td>
            <td>{it.Code}</td>
            <td>{it.Name}</td>
            <td>{it.ClassName}</td>
            <td>{it.ClassCode}</td>         
          </tr>      
      })}
      </tbody>
    </Table>
  )
}

const Pager = ({ totalPage, setSearchOptions, searchOptions }) => {
  const handlePageClick = page => {
    setSearchOptions({ ...searchOptions, nowPage: page })
  }
  const currentPage = parseInt(searchOptions.nowPage)
  const firstPage = currentPage > 10 ? currentPage - (currentPage % 10) : 1
  const totalPageItem = totalPage < 11 ? totalPage : 10
  return (
    <Pagination>
      <Pagination.First onClick={() => handlePageClick(1)} />
      <Pagination.Prev
        onClick={() => handlePageClick(currentPage === 1 ? 1 : currentPage - 1)}
      />
      {[...Array(totalPageItem)].map((_, index) => {
        const page = firstPage + index
        if (page <= totalPage) {
          return (
            <Pagination.Item
              key={page}
              onClick={() => handlePageClick(page)}
              active={currentPage === page}>
              {page}
            </Pagination.Item>
          )
        }
      })}
      <Pagination.Next
        onClick={() =>
          handlePageClick(
            totalPage == currentPage ? currentPage : currentPage + 1
          )
        }
      />
      <Pagination.Last onClick={() => handlePageClick(totalPage)} />
    </Pagination>
  )
}

const fetchInvoice = searchOptions =>
  Promise.all(
    [agent.GetCardListIndex].map(url =>
      fetch(url, {
        body: JSON.stringify(searchOptions),
        headers: {
          'user-agent': 'Mozilla/4.0 MDN Example',
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + loginUser().Token
        },
        method: 'POST'
      })
    )
  ).then(response => Promise.all(response.map(it => it.json())))

const CardList = () => {
  const [card, setCard] = useState([])
  const [searchOptions, setSearchOptions] = useState(initalSearchState)

  useEffect(() => {
    setCard(
      Object.assign({}, card, { isSuccess: false, message: '查詢中...' })
    )
    fetchInvoice(searchOptions)
      .then(([query]) => {       
        const totalPage = Math.ceil(query.Data.TotalItemCount / searchOptions.pageSize)
        return setCard(
          Object.assign({}, { collection: query.Data.Rows, isSuccess: query.IsSuccess, TotalItemCount: query.Data.TotalItemCount, totalPage })
        )
      })
      .catch(({ message }) => {
        setCard(Object.assign({}, { isSuccess: false, message }))
      })
  }, [searchOptions])

  return (
    <> 
      {card.isSuccess ? (
        <Row>
          <Col>
            <TableComponent
              data={card.collection}
              searchOptions={searchOptions}
              setSearchOptions={setSearchOptions}
            />
            共{card.TotalItemCount}筆資料 第{searchOptions.nowPage}頁/共
            {card.totalPage}頁
            <Pager
              totalPage={card.totalPage}
              setSearchOptions={setSearchOptions}
              searchOptions={searchOptions}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>{card.message || '載入中...'}</Col>
        </Row>
      )}
    </>
  )
}

export default CardList
