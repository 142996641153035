import React, {useEffect, useState} from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { agent } from '../share/api'

const Login = props => {

  const [password, setPassword] = useState('')
  const [account, setAccount] = useState('')
  const [pass, setPass] = useState(false)

const login = e =>{
  e.preventDefault()
  localStorage.removeItem('d');
  const form = e.target
  if (!form.checkValidity) {
    e.stopPropagation()
  }
  setPass(true) 
  fetch(agent.Login, {
    body: JSON.stringify({  
      "account": account,
      "password": password,   
    }),
    headers: {
      'user-agent': 'Mozilla/4.0 MDN Example',
      'content-type': 'application/json',       
    },
    method: 'POST'
  }).then(rs => rs.json())
    .then(rsJson => {
      alert(rsJson.Message)
        if(rsJson.IsSuccess){
          localStorage.setItem('d', JSON.stringify(rsJson))
          props.history.push('/back/List')
        }
    })
}

  return <Card>
  <Card.Header>登入MyInvoice</Card.Header>
  <Card.Body>
    <Form noValidate validated={pass} onSubmit={login}>     
      <Form.Group controlId="formAccount">
        <Form.Label>帳號</Form.Label>
        <Form.Control
          type="text"
          placeholder="帳號"
          pattern="[a-zA-Z0-9]{6,}"
          onChange={e => {
            setAccount(e.target.value)
          }}
          value={account}
          required
        />
        <Form.Control.Feedback type="invalid">
          請輸入帳號
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formBasicPassword">
        <Form.Label>密碼</Form.Label>
        <Form.Control
          type="password"
          placeholder="密碼"
          pattern="[a-zA-Z0-9]{6,}"
          onChange={e => {
            setPassword(e.target.value)
          }}
          value={password}
          required
        />
        <Form.Control.Feedback type="invalid">
          請輸入密碼，至少6位的密碼限制
        </Form.Control.Feedback>
      </Form.Group>     
      <Button variant="primary" type="submit">
        登入
      </Button>
    </Form>
  </Card.Body>
</Card>
}

export default Login
