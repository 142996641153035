import React, { useEffect } from 'react'
import { dateFormat } from '../share/format'
import { agent } from '../share/api'
import { loginUser } from '../share/loginUser'
import '../App.css'
import moment from 'moment';
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import markerIconGreenUrl from '../marker-icon-2x-green.png';
import markerIconBlueUrl from '../marker-icon-2x-blue.png';
import markershadowurl from '../marker-shadow.png';
import "leaflet.markercluster"
import "leaflet.markercluster/dist/MarkerCluster.css"
import "leaflet.markercluster/dist/MarkerCluster.Default.css"
const Map = () => {

  useEffect(() => {

    // 使用 leaflet-color-markers ( https://github.com/pointhi/leaflet-color-markers ) 當作 marker
    const greenIcon = new L.Icon({
      iconUrl: markerIconGreenUrl,
      shadowUrl: markershadowurl,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });

    const blueIcon = new L.Icon({
      iconUrl: markerIconBlueUrl,
      shadowUrl: markershadowurl,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });

    const mymap = L.map(document.getElementById("mapid")).setView([23.546162, 120.6402133], 8);

    const OSMUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

    L.tileLayer(OSMUrl).addTo(mymap);

    mymap.locate({
      setView: true,
      watch: false,
      maxZoom: 17,
      enableHighAccuracy: true,
    })
      .on('locationfound', function (e) {
        var marker = L.marker([e.latitude, e.longitude], { icon: blueIcon }).bindPopup('我在這 :)');
        var circle = L.circle([e.latitude, e.longitude], e.accuracy / 2, {
          weight: 1,
          color: 'blue',
          fillColor: '#cacaca',
          fillOpacity: 0.2
        });
        mymap.addLayer(marker);
        mymap.addLayer(circle);
      })
      .on('locationerror', function (e) {
        console.log("Location access denied.", e)
      });

    const markerClusterGroup = L.markerClusterGroup({
      chunkedLoading: true,
      maxClusterRadius: 60,
      animateAddingMarkers: false,
      disableClusteringAtZoom: 17,
      spiderfyOnMaxZoom: false,
    })

    Promise.all(
      [fetch(agent.MapGetListIndex, {
        headers: {
          'user-agent': 'Mozilla/4.0 MDN Example',
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + loginUser().Token
        },
        method: 'Get'
      })])
      .then(response => Promise.all(response.map(it => it.json())))
      .then(resJson => {
        resJson[0].Data.forEach(it => {

          let marker = L.marker([it.Y, it.X], { icon: greenIcon })

          const popupContent = it.Sellers.map(s => {
            const lastDetailsHtml = s.LastDetails.length > 1 ? '<hr>' + s.LastDetails.map((p, index) => `<li>前第${index + 1}次消費日期：${moment(p.DateTime).format('YYYY/MM/DD')}; 金額：${p.Amount}</li>`).join("") : ''
            const content = `<h5 style="font-weight: bold"> ${s.SellerName} </h5> <br> 統一編號：${s.SellerBan} <br> <span title="TGOS解析位置：${s.FULL_ADDR}">概略地址：${s.SellerAddress || s.FULL_ADDR}</span> <br> 首次造訪：${dateFormat(s.FirstDate)} <br> 總消費金額：${s.Amount} <br> 總消費次數:${s.Count} ${lastDetailsHtml} `
            return content
          }).join("<hr style=\"border: 1px solid;\"/>")


          marker.bindPopup(popupContent);
          markerClusterGroup.addLayer(marker)
        })

        mymap.addLayer(markerClusterGroup)
      })


  })



  return <div id="mapid" style={{ height: "100vh", width: "100vw" }} key={new Date().getTime()} />
}

export default Map
