import React, {useEffect, useState} from 'react'
import {agent} from '../share/api'
import Highcharts from 'highcharts/highstock'



const pieChartOptions = ({elementName, title, suffix, data}) => ({
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    renderTo: document.getElementById(elementName)
  },
  title: {
    text: title
  },
  tooltip: {
    pointFormat: `<b>{point.percentage:.1f}%</b><br/>
<b>{point.y}${suffix}</b>`
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        style: {
          color:
            (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
        }
      }
    }
  },
  series: [
    {
      colorByPoint: true,
      data
    }
  ]
})

const Chart = () => {
  const [chartData, setChartData] = useState({})
  const fetchChartData = Promise.all(
    [agent.ChartCardInfo, agent.MonthOfMoneyInfo].map(url => fetch(url))
  ).then(response => Promise.all(response.map(it => it.json())))
  useEffect(() => {
    fetchChartData.then(([cardCount, monthOfMoney]) => {
      setChartData({cardCount, monthOfMoney})

      Highcharts.chart(
        pieChartOptions({
          elementName: 'chartCardNameCount',
          title: '各載具使用率圓餅圖',
          suffix: '張',
          data: cardCount.query.map(it => ({name: it.cardName, y: it.count}))
        })
      )
      Highcharts.chart(
        pieChartOptions({
          elementName: 'chartCardNameMoney',
          title: '各載具金額總計圓餅圖',
          suffix: '元',
          data: cardCount.query.map(it => ({
            name: it.cardName,
            y: it.totalMoney
          }))
        })
      )

      Highcharts.chart('chartMonthOfMoneyInfo', {
        chart: {
          type: 'column'
        },
        title: {
          text: '每期消費金額'
        },
        xAxis: {
          categories: monthOfMoney.query.map(it => it.yearMonth),
          crosshair: true
        },
        yAxis: {
          min: 0,
          title: {
            text: '金額'
          }
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y} 元</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: [
          {
            name: '金額',
            data: monthOfMoney.query.map(it => it.money)
          }
        ]
      })
    })
  }, chartData)

  return (
    <>
      <div id="chartCardNameCount" />
      <div id="chartCardNameMoney" />
      <div id="chartMonthOfMoneyInfo" />
    </>
  )
}

export default Chart
