import React, { useState } from 'react'
import {
  invoiceStatus,
  dateFormat,
  trimCardCode,
  trimCardName,
  trimSellerName,
  wrapDate
} from '../share/format'
import { readAllLine, getInvoiceObject } from './parseCsv'
import { agent } from '../share/api'
import { loginUser } from '../share/loginUser'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Alert from 'react-bootstrap/Alert'
import Popover from 'react-bootstrap/Popover'
import Table from 'react-bootstrap/Table'

const uploadStatus = {
  ready: '準備上傳',
  loading: '上傳中...',
  success: '上傳成功',
  fail: '失敗',
  canWrite: '可上傳'
}

const popover = invoice => (
  <Popover id="popover-basic" title={invoice.number}>
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>商品名稱</th>
          <th>總金額</th>
        </tr>
      </thead>
      <tbody>
        {invoice.Details.map((it, index) => (
          <tr key={index}>
            <td>{it.Description}</td>
            <td>{it.Amount}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </Popover>
)

const TableComponent = ({ data }) => {
  return (
    <Table striped bordered hover responsive="xl" size="sm">
      <thead>
        <tr>
          <th>上傳狀態</th>
          <th>序號</th>
          <th>狀態</th>
          <th>發票號碼</th>
          <th>日期</th>
          <th>賣方名稱</th>
          <th>載具名稱</th>
          <th>金額</th>
          <th>動作</th>
        </tr>
      </thead>
      <tbody>
        {data.map((it, index) => (
          <tr key={index}>
            <td>{it.uploadStatus}</td>
            <td>{index + 1}</td>
            <td>{it.Status}</td>
            <td>{it.number}</td>
            <td>{it.DateTime}</td>
            <td>{trimSellerName(it.SellerName)}</td>
            <td>{trimCardName(it.ClassName)}</td>
            <td>{it.Amount}</td>
            <td>
              <OverlayTrigger
                trigger={['hover', 'focus', 'click']}
                placement="left-start"
                overlay={popover(it)}>
                <Button variant="info">明細</Button>
              </OverlayTrigger>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const Import = () => {
  const [preview, setPreview] = useState([])
  const [message, setMessage] = useState({
    isSuccess: false,
    text: '請選擇檔案。'
  })
  return (
    <>
      <form
        method="post"
        encType="multipart/form-data"
        onSubmit={event => {
          event.preventDefault()
        }}>
        <label htmlFor="choose-csv">請選擇財政部消費彙整檔案:</label>
        <input
          id="choose-csv"
          type="file"
          accept=".csv"
          onChange={({ target }) => {
            const { value } = target
            const reader = new FileReader()

            reader.onload = () => {
              const text = reader.result
              if (text) {
                const { obj, hasError, message } = getInvoiceObject(
                  readAllLine(text)
                )
                setPreview(
                  Object.values(obj).map(it => ({
                    ...it,
                    uploadStatus: uploadStatus.ready
                  }))
                )
                setMessage({
                  isSuccess: !hasError,
                  text: message
                })
              }
            }

            reader.readAsText(target.files[0], 'big5')
          }}
        />
        <button
          disabled={!message.isSuccess}
          onClick={() => {
            const invoiceData = Object.values(preview) || []
            if (invoiceData.length > 0) {
              setMessage(
                Object.assign({}, message, {
                  isSuccess: false,
                  text: '上傳中...請稍候...'
                })
              )
              setPreview(
                preview.map(inv => ({
                  ...inv,
                  uploadStatus: uploadStatus.loading
                }))
              )

              fetch(agent.Upload, {
                body: JSON.stringify(invoiceData),
                headers: {
                  'user-agent': 'Mozilla/4.0 MDN Example',
                  'content-type': 'application/json',
                  'Authorization': 'Bearer ' + loginUser().Token
                },
                method: 'POST'
              })
                .then(response => response.json())
                .then(({ IsSuccess, Message }) => {
                  setPreview(
                    preview.map(inv => ({
                      ...inv,
                      uploadStatus: IsSuccess ? uploadStatus.success : uploadStatus.fail
                    }))
                  )
                  setMessage({ isSuccess: IsSuccess, text: Message })
                })
            }
          }}>
          上傳
        </button>
      </form>
      {message.isSuccess ? (
        <Alert variant="success">{message.text}</Alert>
      ) : (
        <Alert variant="danger"> {message.text}</Alert>
      )}

      <TableComponent data={preview} />
    </>
  )
}

export default Import
