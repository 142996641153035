import React, { useEffect, useState } from 'react'
import { browserHistory } from 'react-router'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { agent } from '../share/api'

const Signup = props => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [checkPassword, setCheckPassword] = useState('')
  const [name, setName] = useState('')
  const [account, setAccount] = useState('')
  const [pass, setPass] = useState(false)
  const save = e => {
    e.preventDefault()
    const form = e.target
    if (!form.checkValidity) {
      e.stopPropagation()
    }
    setPass(true) 
    fetch(agent.Register, {
      body: JSON.stringify({
        "name": name,
        "account": account,
        "password": password,
        "vetifyPassword": checkPassword,
        "email": email
      }),
      headers: {
        'user-agent': 'Mozilla/4.0 MDN Example',
        'content-type': 'application/json',       
      },
      method: 'POST'
    }).then(rs => rs.json())
      .then(rsJson => {
        alert(rsJson.Message)
          if(rsJson.IsSuccess){
            alert('請重新登入')
            props.history.push('/')
          }
      })
  }


  return (
    <Card>
      <Card.Header>註冊Myinvoice</Card.Header>
      <Card.Body>
        <Form noValidate validated={pass} onSubmit={save}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>電子郵件</Form.Label>
            <Form.Control
              type="email"
              placeholder="電子郵件位置"
              onChange={e => {
                setEmail(e.target.value)
              }}             
              required
            />
            <Form.Control.Feedback type="invalid">
              請輸入正確的電子郵件位置
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formAccount">
            <Form.Label>帳號</Form.Label>
            <Form.Control
              type="text"
              placeholder="帳號"
              pattern="[a-zA-Z0-9]{6,}"
              onChange={e => {
                setAccount(e.target.value)
              }}
              required
            />
            <Form.Control.Feedback type="invalid">
              請輸入帳號
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>密碼</Form.Label>
            <Form.Control
              type="password"
              placeholder="密碼"
              pattern="[a-zA-Z0-9]{6,}"
              onChange={e => {
                setPassword(e.target.value)
              }}
              required
            />
            <Form.Control.Feedback type="invalid">
              請輸入密碼，至少6位的密碼限制
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formBasicCheckPassword">
            <Form.Label>請再輸入一次密碼</Form.Label>
            <Form.Control
              type="password"
              placeholder="確認密碼"
              pattern="[a-zA-Z0-9]{6,}"
              onChange={e => {
                setCheckPassword(e.target.value)
              }}
              required
            />
            <Form.Control.Feedback type="invalid">
              密碼需一致，至少6位密碼限制
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formBasicName">
            <Form.Label>該怎麼稱呼您</Form.Label>
            <Form.Control
              type="text"
              placeholder="該怎麼稱呼您"
              onChange={e => {
                setName(e.target.value)
              }}
              required
            />
            <Form.Control.Feedback type="invalid">
              請輸入名稱
            </Form.Control.Feedback>
          </Form.Group>
          <Button variant="primary" type="submit">
            註冊
          </Button>
        </Form>
      </Card.Body>
    </Card>
  )
}

export default Signup
