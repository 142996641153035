import moment from 'moment';

const invoiceStatus = {
  1: '開立',
  2: '作廢'
}

const dateFormat = text => {
  text = moment(text).format('YYYY-MM-DD HH:mm:ss')

  return text
}

const wrapDate = text => {
  const [y1, y2, y3, y4, m1, m2, d1, d2] = text
  return y1 + y2 + y3 + y4 + '-' + m1 + m2 + '-' + d1 + d2
}

const trimCardCode = text => {
  if (text.length > 10) {
    return text.substring(0, 10) + '...'
  }
  return text
}
const trimCardName = text => {
  const replaceText = ['電子發票會員載具', '股份有限公司', '(變動載具)']
  replaceText.forEach(it => (text = text.replace(it, '')))
  return text
}
const trimSellerName = text => {
  const replaceText = ['股份有限公司', '油品行銷事業部', '(股)公司', '有限公司']
  replaceText.forEach(it => (text = text.replace(it, '')))
  return text
}

export {
  invoiceStatus,
  dateFormat,
  trimCardCode,
  trimCardName,
  trimSellerName,
  wrapDate
}
