import React, { useEffect, useState } from 'react'
import { browserHistory } from 'react-router'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { agent } from '../share/api'
import { dateFormat } from '../share/format'
import { loginUser } from '../share/loginUser'
import linenotifyqrcode from '../img_lp02_zh_TW.png';

const Notification = props => {
  const [isBindLineNotify, setIsBindLineNotify] = useState(false)
  const [isLoadFinish, setIsLoadFinish] = useState(false)
  const locationUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;

  const cancelLineNotify = e => {
    if (window.confirm('將會收不到通知，是否解除綁定？')) {
      saveNotification("", locationUrl)
    }
  }

  const goToLineNotifyPage = e => {
    e.preventDefault()

    let url = new URL("https://notify-bot.line.me/oauth/authorize");
    url.searchParams.append("response_type", "code");
    url.searchParams.append("client_id", "L8B3jGgXnpkKn5CqybvhhD");
    url.searchParams.append("redirect_uri", locationUrl);
    url.searchParams.append("scope", "notify");
    url.searchParams.append("state", 1);
    if (window.confirm('即將跳轉至連動頁，是否已加入LineNotify好友？')) {
      window.location.href = url.href;
    }
  }

  const saveNotification = (code, redirectUri) => {
    return fetch(agent.SaveNotification, {
      body: JSON.stringify({
        "code": code,
        "redirectUri": redirectUri
      }),
      headers: {
        'user-agent': 'Mozilla/4.0 MDN Example',
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + loginUser().Token
      },
      method: 'POST'
    }).then(rs => rs.json())
      .then(rsJson => {
        alert(rsJson.Message)
        if (rsJson.IsSuccess) {
          setIsBindLineNotify(rsJson.Data.IsBindLineNotify)
        }
        window.location.href = locationUrl
      })
  }

  const testNotification = () => {
    return fetch(agent.TestLineNotification, {
      body: JSON.stringify({
      }),
      headers: {
        'user-agent': 'Mozilla/4.0 MDN Example',
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + loginUser().Token
      },
      method: 'POST'
    }).then(rs => rs.json())
      .then(rsJson => {
        alert(rsJson.Message)
      })
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('state') === '1' && urlParams.get("code") !== null) {
      saveNotification(urlParams.get("code"), locationUrl)
    }

    fetch(agent.GetNotification, {
      headers: {
        'user-agent': 'Mozilla/4.0 MDN Example',
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + loginUser().Token
      },
      method: 'GET'
    }).then(rs => rs.json())
      .then(rsJson => {
        if (rsJson.IsSuccess) {
          setIsBindLineNotify(rsJson.Data.IsBindLineNotify)
          setIsLoadFinish(true)
        }
      })
  }, [])

  return (isLoadFinish ?
    <Card>
      <Card.Header>通知設定</Card.Header>
      <Card.Body>
        <Form>
          <Form.Row>
            <img src={linenotifyqrcode} />
          </Form.Row>
          <Form.Row>
            將「LINE Notify」加入好友：<a href="https://line.me/R/ti/p/%40linenotify">連結</a>
          </Form.Row>
          {
            isBindLineNotify ? <Form.Row>已成功綁定LineNotify，下面按扭可解除綁定</Form.Row> : <Form.Row>
              請掃描QRCode或點選上面連結加入好友，再進行綁定。
            </Form.Row>
          }
          <Form.Row>
            {isBindLineNotify ? <Button variant="primary" onClick={e => cancelLineNotify(e)}>
              解除綁定
            </Button> : <Button variant="primary" onClick={e => goToLineNotifyPage(e)}>
              連結到LineNotify
            </Button>
            }
          </Form.Row>
          <Form.Row>
            {isBindLineNotify ? "想測試：" : "" }
          </Form.Row>
          {isBindLineNotify ? <Form.Row>
            <Button variant="primary" onClick={e => testNotification(e)}>
              測試發送通知
            </Button>
          </Form.Row> : <Form.Row></Form.Row>
          }
        </Form>
      </Card.Body>
    </Card> : <Card>
      <Card.Header>通知設定</Card.Header>
      <Card.Body>
        <Form>
          <Form.Row>
            讀取中...
          </Form.Row>
        </Form>
      </Card.Body>
    </Card>
  )
}

export default Notification
