import React, { useEffect, useState } from 'react'
import { browserHistory } from 'react-router'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { agent } from '../share/api'
import { dateFormat } from '../share/format'
import { loginUser } from '../share/loginUser'

const ChangePassword = props => { 
  const [oldPassword, setOldPassword] = useState('')
  const [password, setPassword] = useState('')
  const [vetifyPassword, setVetifyPassword] = useState('')
  const [pass, setPass] = useState(false)
  const save = e => {
    e.preventDefault()
    const form = e.target
    if (!form.checkValidity) {
      e.stopPropagation()
    }
    setPass(true)
    fetch(agent.ChangePassword, {
      body: JSON.stringify({
        "oldPassword": oldPassword,
        "password": password,
        "vetifyPassword": vetifyPassword,
      }),
      headers: {
        'user-agent': 'Mozilla/4.0 MDN Example',
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + loginUser().Token
      },
      method: 'POST'
    }).then(rs => rs.json())
      .then(rsJson => {
        alert(rsJson.Message)      
      })
  }


  return (
    <Card>
      <Card.Header>修改密碼</Card.Header>
      <Card.Body>
        <Form noValidate validated={pass} onSubmit={save}>
        <Form.Group controlId="formOldPassword">
            <Form.Label>舊密碼</Form.Label>
            <Form.Control
              type="password"
              placeholder="舊密碼"
              pattern="[a-zA-Z0-9]{6,}"
              onChange={e => {
                setOldPassword(e.target.value)
              }}
              required
            />
            <Form.Control.Feedback type="invalid">
              請輸入舊密碼，至少6位的密碼限制
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>新密碼</Form.Label>
            <Form.Control
              type="password"
              placeholder="密碼"
              pattern="[a-zA-Z0-9]{6,}"
              onChange={e => {
                setPassword(e.target.value)
              }}
              required
            />
            <Form.Control.Feedback type="invalid">
              請輸入新密碼，至少6位的密碼限制
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formBasicCheckPassword">
            <Form.Label>請再輸入一次新密碼</Form.Label>
            <Form.Control
              type="password"
              placeholder="確認新密碼"
              pattern="[a-zA-Z0-9]{6,}"
              onChange={e => {
                setVetifyPassword(e.target.value)
              }}
              required
            />
            <Form.Control.Feedback type="invalid">
              密碼需一致，至少6位密碼限制
            </Form.Control.Feedback>
          </Form.Group>        
          <Button variant="primary" type="submit">
            儲存
          </Button>
        </Form>
      </Card.Body>
    </Card>
  )
}

export default ChangePassword
