import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Form from 'react-bootstrap/Form'


const Bar = () => (
  <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
    <Navbar.Brand as={Link} to="/back/">
      發票列表
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="mr-auto">
        <Nav.Link as={Link} to="/back/Map">
          地圖檢視
        </Nav.Link>
        <Nav.Link as={Link} to="/back/WinList">
          對獎結果
        </Nav.Link>
        <Nav.Link as={Link} to="/back/CardList">
          載具列表
        </Nav.Link>
        <Nav.Link as={Link} to="/back/Import">
          上傳發票
        </Nav.Link>
        <Nav.Link as={Link} to="/back/APISetting">
          介接設定
        </Nav.Link>
        <Nav.Link as={Link} to="/back/Notification">
          通知設定
        </Nav.Link>
        <Nav.Link as={Link} to="/back/HistoryList">
          同步歷史
        </Nav.Link>
        <Nav.Link as={Link} to="/back/SellerLocation">
          賣方資料
        </Nav.Link>
        <Nav.Link as={Link} to="/back/UpdateSellerLocation">
          更新賣方資料
        </Nav.Link>
        {/* <Nav.Link as={Link} to="/back/Chart">
            圖表
          </Nav.Link>
          <Nav.Link as={Link} to="/back/Report">
            報表
          </Nav.Link>
          <Nav.Link as={Link} to="/back/RecentList">
            最近發票
          </Nav.Link> 
          <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">
              Another action
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">
              Separated link
            </NavDropdown.Item>
          </NavDropdown>*/}
      </Nav>
      <Nav>
        <Nav.Link as={Link} to="/back/ChangePassword">
          修改密碼
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
)

export default Bar
