import React, { useEffect, useState } from 'react'
import { browserHistory } from 'react-router'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { agent } from '../share/api'
import { dateFormat } from '../share/format'
import { loginUser } from '../share/loginUser'

const APISetting = props => {
  const [EInvoiceAPISettingID, setEInvoiceAPISettingID] = useState(0)
  const [CardNumber, setCardNumber] = useState('')
  const [Password, setPassword] = useState('')
  const [ConnectSatate, setConnectSatate] = useState('')
  const [LastSuccess, setLastSuccess] = useState('')
  const [pass, setPass] = useState(false)
  const save = e => {
    e.preventDefault()
    const form = e.target
    if (!form.checkValidity) {
      e.stopPropagation()
    }
    setPass(true)
    fetch(agent.SaveAPISetting, {
      body: JSON.stringify({
        "eInvoiceAPISettingID": EInvoiceAPISettingID,
        "cardNumber": CardNumber,
        "password": Password,
      }),
      headers: {
        'user-agent': 'Mozilla/4.0 MDN Example',
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + loginUser().Token
      },
      method: 'POST'
    }).then(rs => rs.json())
      .then(rsJson => {
        alert(rsJson.Message)
        if (rsJson.IsSuccess) {
          setEInvoiceAPISettingID(rsJson.Data.EInvoiceAPISettingID)
          setCardNumber(rsJson.Data.CardNumber)
          setConnectSatate(rsJson.Data.ConnectSatate)
          setLastSuccess(dateFormat(rsJson.Data.LastSuccess))
        }
      })
  }

  const eInvoiceConnectSatate = {
    1: 'OK',
    2: 'Fail',
    3: 'NotTest'
  }

  useEffect(() => {
    fetch(agent.GetAPISetting, {
      headers: {
        'user-agent': 'Mozilla/4.0 MDN Example',
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + loginUser().Token
      },
      method: 'GET'
    }).then(rs => rs.json())
      .then(rsJson => {
        if (rsJson.IsSuccess) {
          setEInvoiceAPISettingID(rsJson.Data.EInvoiceAPISettingID)
          setCardNumber(rsJson.Data.CardNumber)
          setConnectSatate(rsJson.Data.ConnectSatate)
          setLastSuccess(dateFormat(rsJson.Data.LastSuccess))
        }
      })
  }, [])

  return (
    <Card>
      <Card.Header>介接設定</Card.Header>
      <Card.Body>
        <Form noValidate validated={pass} onSubmit={save}>
          <Form.Group controlId="formCardNumber">
            <Form.Label>手機條碼(含/，注意是大寫)</Form.Label>
            <Form.Control
              type="text"
              placeholder="手機條碼 例如：/Y96VK5Q"
              value={CardNumber}
              onChange={e => {
                setCardNumber(e.target.value)
              }}
              required
            />
            <Form.Control.Feedback type="invalid">
              請輸入正確的手機條碼
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formPassword">
            <Form.Label>驗證碼 (電子發票平台驗證碼)</Form.Label>
            <Form.Control
              type="password"
              placeholder="驗證碼"
              onChange={e => {
                setPassword(e.target.value)
              }}
              required
            />
            <Form.Control.Feedback type="invalid">
              請輸入驗證碼
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>連線狀態</Form.Label>
            <Form.Control
              type="text"
              value={eInvoiceConnectSatate[ConnectSatate] || ''}
              readOnly
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>上次成功</Form.Label>
            <Form.Control
              type="text"
              value={LastSuccess}
              readOnly
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            儲存
          </Button>
        </Form>
      </Card.Body>
    </Card>
  )
}

export default APISetting
