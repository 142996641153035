import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter, Route} from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Login from './Login'
import Signup from './Signup'
import Home from './Home'
const front = () => (
  <>
    <Route path="/" exact component={Home} />
    <Route path="/front" exact component={Home} />
    <Route path="/front/Login" component={Login} />
    <Route path="/front/Signup" component={Signup} />
    <Route path="/front/Home" component={Home} />
  </>
)
export default front
