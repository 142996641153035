const server = {
  localhost: 'https://localhost:44378',
  Azure: 'https://webinv.azurewebsites.net',
  myInv: 'https://myinv.jkv.tw'
}

const currentServer = (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") ?
  'localhost' : 'Azure'

const Url = server[currentServer]

const agent = {
  InvoiceQuery: Url + 'Invoice/Query',
  Import: Url + 'Import',
  MapGetListIndex: Url + '/Map/GetListIndex',
  ChartCardInfo: Url + 'Chart/CardInfo',
  MonthOfMoneyInfo: Url + 'Chart/MonthOfMoneyInfo',
  ProductMoneyInfo: Url + 'Report/ProductMoneyInfo',
  Register: Url + '/User/Register',
  Login: Url + '/User/Login',
  ChangePassword: Url + '/User/ChangePassword',
  GetListIndex: Url + '/EInvoice/GetListIndex',
  GetWinListIndex: Url + '/EInvoice/GetWinListIndex',
  GetListStatus: Url + '/EInvoice/GetListStatus',
  GetCardListIndex: Url + '/EInvoice/GetCardListIndex',
  GetHistoryListIndex: Url + '/EInvoice/GetHistoryListIndex',
  Upload: Url + '/EInvoice/Upload',
  GetAPISetting: Url + '/SystemSetting/GetAPISetting',
  SaveAPISetting: Url + '/SystemSetting/SaveAPISetting',
  GetNotification: Url + '/SystemSetting/GetNotification',
  SaveNotification: Url + '/SystemSetting/SaveNotification',
  TestLineNotification: Url + '/SystemSetting/TestLineNotification',
  GetCanPrizeList: Url + '/EInvoice/GetCanPrizeList',
  DoPrize: Url + '/EInvoice/DoPrize',
  GetNotFoundLocationListIndex: Url + '/SellerLocation/GetNotFoundLocationListIndex',
  SellerLocationSave: Url + '/SellerLocation/Save',
}

export { agent }
